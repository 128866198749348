import React, { useState } from 'react';
import logoHeart from '../assets/images/logotwo.png';
import { FiSun } from 'react-icons/fi';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { getDatabase, ref, update } from 'firebase/database';

function Login() {
  const [lastLoggedIn, setLastLoggedIn] = useState(null);

  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [uid, setUid] = useState(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get('redirect');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailError('');
    setPasswordError('');
    try {
      await signInWithEmailAndPassword(auth, user.email, user.password);
      if (auth.currentUser) {
        setUid(auth.currentUser.uid);

        const currentTimestamp = Math.floor(Date.now() / 1000);
        setLastLoggedIn(currentTimestamp);

        const db = getDatabase();
        const userRef = ref(db, `users/${auth.currentUser.uid}`);
        update(userRef, {
          lastActive: currentTimestamp,
        });

        navigate(redirectPath || '/dashboard');
      }
    } catch (err) {
      switch (err.code) {
        case 'auth/invalid-email':
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          setEmailError('Invalid email.');
          break;
        case 'auth/wrong-password':
          setPasswordError('Incorrect password.');
          break;
        default:
          console.log(err, 'An error occurred. Please try again.');
      }
    }
  };

  return (
    <>
      <div className="w-full min-h-screen bg-[#f9fafb] absolute left-0 top-0">
        <div className="w-full h-[128px] bg-[#9b111e] text-white flex">
          <div className="flex self-center mx-auto w-min">
            <img
              src={logoHeart}
              alt="heart"
              className="w-1/5 mr-5 -ml-5 scale-150 lg:scale-[150%] lg:mr-10 lg:-ml-20"
            />
            <div
              className="w-4/5 font-bold h-min text-[20px] self-center
              lg:text-[40px]"
            >
              {' '}
              PinaSoulmate
            </div>
          </div>
        </div>

        {/* login form */}
        <form
          className="rounded-[17px] w-[80vw] max-w-[576px] h-min mt-[56px] bg-white mx-auto equal-shadow p-[55px] text-[#374756]"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="mx-auto w-min">
            <FiSun className="w-[50px] h-[50px] text-[#374756]" />
          </div>
          <div className="text-center">
            <h2 className="text-[26px] font-semibold mt-5">
              Login to your account
            </h2>
            <div className="mt-5 ">Enter your email</div>
            <input
              required
              type="email"
              placeholder="email"
              autoFocus
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
              className="bg-[#FAFAFA] w-full min-h-[44px] h-min rounded-[6px] pl-3 border-[2px] mt-5"
            />
            {emailError && (
              <div className="mt-2 text-red-500">{emailError}</div>
            )}{' '}
            <div className="mt-5 ">Enter your password</div>
            <input
              required
              type="password"
              placeholder="password"
              value={user.password}
              onChange={(e) => setUser({ ...user, password: e.target.value })}
              className="bg-[#FAFAFA] w-full min-h-[44px] h-min rounded-[6px] pl-3 border-[2px] mt-5"
            />
            {passwordError && (
              <div className="mt-2 text-red-500">{passwordError}</div>
            )}
          </div>
          <div className="text-center">
            {uid && (
              <div className="text-green-500">
                Logged in with UID: {uid} {lastLoggedIn}
              </div>
            )}
          </div>
          <button
            className="w-full h-[44px] rounded-[6px] bg-[#9B111E] text-white mt-8 hover:mt-7 hover:mb-1 duration-150"
            type="submit"
          >
            Log in
          </button>
        </form>
        <Link to="/setup">
          <p className="text-[#808080] cursor-pointer text-center my-5 mb-24">
            Create an account
          </p>
        </Link>
      </div>
    </>
  );
}

export default Login;
