import React, { useState } from 'react';
import logoHeart from '../assets/images/logotwo.png';
import { BsCheckLg, BsArrowLeft } from 'react-icons/bs';
import { FiSun } from 'react-icons/fi';

import c from 'clsx';
import profileSetup from '../assets/dummydata/ProfileSetup';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';

import { storage } from '../firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { v4 } from 'uuid';

import { set, ref as dbref } from 'firebase/database';
import { db } from '../firebase';
import { Link } from 'react-router-dom';

import { cn } from '../utils/cn';

import useProfileObject from '../state/profileObject';

import imageCompression from 'browser-image-compression';

import Loader from '../components/Loader';

function Setup() {
  const [terms, setTerms] = useState(false);
  const [selectedAge, setSelectedAge] = useState(18);
  const [currentNumber, setCurrentNumber] = useState(-1);
  const [user, setUser] = useState({
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [holdUserUID, setHoldUserUID] = useState(null);

  const [profilePictures, setProfilePictures] = useState([]);
  const [imagesLoading, setImagesLoading] = useState(false);

  const uploadedImage =
    profilePictures.length >= 1 && profilePictures.length <= 5;

  const lastStep = 11;
  const isOnLastStep = currentNumber === lastStep;
  const continueDisabled = isOnLastStep && (imagesLoading || !uploadedImage);

  const Numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const profileState = useProfileObject();

  const {
    setId,
    setName,
    setAge,
    setGender,
    setLocation,
    setChildren,
    setEthnicity,
    setRelationship,
    setReligion,
    setImages,
    setAboutMe,
    setSeeking,
  } = useProfileObject();

  const handleInputChange = (e, questionItem) => {
    const value = e.target.value;
    switch (questionItem.question) {
      case 'What is your name?':
        setName(value);
        break;
      case 'What city and country do you live in?':
        setLocation(value);
        break;
      case 'Tell us about yourself!':
        setAboutMe(value);
        break;
      case 'What are you looking for in a partner?':
        setSeeking(value);
        break;
      default:
        break;
    }
  };

  const handleOptionClick = (option, questionItem) => {
    switch (questionItem.question) {
      case 'What is your gender?':
        setGender(option);
        break;
      case 'Do you have children?':
        setChildren(option);
        break;
      case 'What is your Ethnicity?':
        setEthnicity(option);
        break;
      case 'What type of relationship are you looking for?':
        setRelationship(option);
        break;
      case 'What is your religion?':
        setReligion(option);
        break;
      default:
        break;
    }
    setCurrentNumber(currentNumber + 1);
  };

  const writeToDatabase = () => {
    const getCurrentTimestamp = () => Math.floor(Date.now() / 1000);

    set(dbref(db, `/users/${holdUserUID}`), {
      id: profileState.id,
      name: profileState.name,
      age: profileState.age,
      gender: profileState.gender,
      location: profileState.location,
      children: profileState.children,
      ethnicity: profileState.ethnicity,
      relationship: profileState.relationship,
      religion: profileState.religion,
      aboutMe: profileState.aboutMe,
      seeking: profileState.seeking,
      profilePictures,
      admin: false,
      billing: 'free',
      code: 'none',
      likes: ['-1'],
      favorites: ['-1'],
      likedBy: ['-1'],
      favoritedBy: ['-1'],
      lastActive: getCurrentTimestamp(),
    });
  };

  const handleImageUpload = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImagesLoading(true);
      const imagesArray = Array.from(e.target.files).slice(0, 5);
      const compressedImages = [];

      for (let image of imagesArray) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        try {
          const compressedFile = await imageCompression(image, options);
          compressedImages.push(compressedFile);
        } catch (error) {
          console.error('Error compressing image:', error);
        }
      }

      setImages(compressedImages);

      const uploadPromises = compressedImages.map((compressedImage) => {
        const uniqueName = `${compressedImage.name}-${v4()}`;
        const storageRef = ref(storage, `images/${holdUserUID}/${uniqueName}`);
        const uploadTask = uploadBytesResumable(storageRef, compressedImage);

        return new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            null,
            (error) => {
              console.error('Error uploading file:', error);
              reject(error);
            },
            async () => {
              try {
                const downloadURL = await getDownloadURL(
                  uploadTask.snapshot.ref,
                );
                resolve(downloadURL);
              } catch (error) {
                console.error('Error getting download URL:', error);
                reject(error);
              }
            },
          );
        });
      });

      Promise.all(uploadPromises)
        .then((downloadURLs) => {
          setProfilePictures((prevPictures) => [
            ...prevPictures,
            ...downloadURLs,
          ]);
        })
        .catch((error) => {
          console.error('Error in uploading one or more images:', error);
        })
        .finally(setImagesLoading(false));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = user;

    if (!email.trim()) {
      setErrorMessage('Email cannot be empty');
      return;
    }
    if (!password.trim() || password.length < 6) {
      setErrorMessage('Password should be at least 6 characters long');
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        setErrorMessage(null);
        setCurrentNumber(currentNumber + 1);

        setHoldUserUID(user.uid);
        setId(user.uid);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('Error registering:', errorCode, errorMessage);
        setErrorMessage(errorMessage);
      });
  };

  const continueForm = (e) => {
    e.preventDefault();
    if (isOnLastStep) writeToDatabase();

    setCurrentNumber(currentNumber + 1);
  };

  return (
    <>
      <div className="w-full min-h-screen bg-[#f9fafb] absolute left-0 top-0">
        <div className="w-full h-[128px] bg-[#9b111e] text-white flex">
          <div className="flex self-center mx-auto w-min">
            <img
              src={logoHeart}
              alt="heart"
              className="w-1/5 mr-5 -ml-5 scale-150 lg:scale-[150%] lg:mr-10 lg:-ml-20"
            />
            <div
              className="w-4/5 font-bold h-min text-[20px] self-center
              lg:text-[40px]"
            >
              {' '}
              PinaSoulmate
            </div>
          </div>
        </div>
        <div className="w-full h-min">
          {currentNumber >= 1 && currentNumber <= 11 && (
            <div className="flex justify-center mt-10">
              <div className="flex flex-row mx-auto text-white text-[23px] w-min scale-[50%] sm:scale-100">
                {Numbers.map((number, index) => (
                  <React.Fragment key={index}>
                    {number !== 1 && (
                      <div
                        className={`w-[16px] h-[3px] my-auto ${
                          number > currentNumber
                            ? 'bg-[#b76e79]'
                            : 'bg-[#9B111E]'
                        }`}
                      ></div>
                    )}
                    {number + 1 > currentNumber ? (
                      <div
                        className={c(
                          'w-[41px] h-[41px] rounded-full bg-[#b76e79] grid place-content-center border-[2px] duration-500',
                          number === currentNumber
                            ? 'border-[#9B111E]'
                            : 'border-[#b76e79]',
                        )}
                      >
                        {number}
                      </div>
                    ) : (
                      <div className="w-[41px] h-[41px] rounded-full bg-[#9B111E] grid place-content-center border-[2px] border-[#9b111e] duration-500">
                        <BsCheckLg />
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}

          {currentNumber === -1 ? (
            <div className="rounded-[17px] w-[80vw] max-w-[576px] h-min mt-[56px] bg-white mx-auto equal-shadow p-[55px] text-[#374756]">
              <div className="mx-auto w-min">
                <FiSun className="w-[50px] h-[50px] text-[#374756]" />
              </div>
              <div className="text-center">
                <h2 className="text-[26px] font-semibold mt-5">Welcome!</h2>
                <div className="mt-5"> Lets create your account</div>
              </div>
              <button
                className="w-full h-[44px] rounded-[6px] bg-[#9B111E] text-white mt-8 hover:mt-7 hover:mb-1 duration-150"
                onClick={() => setCurrentNumber(currentNumber + 1)}
              >
                Continue
              </button>
            </div>
          ) : currentNumber === 0 ? (
            <>
              <form
                className="rounded-[17px] w-[80vw] max-w-[576px] h-min mt-[56px] bg-white mx-auto equal-shadow p-[55px] text-[#374756]"
                onSubmit={(e) => handleSubmit(e)}
              >
                <div className="mx-auto w-min">
                  <FiSun className="w-[50px] h-[50px] text-[#374756]" />
                </div>
                <div className="text-center">
                  <h2 className="text-[26px] font-semibold mt-5">
                    Create a login
                  </h2>
                  <div className="mt-5 ">Enter your email</div>
                  <input
                    required
                    type="email"
                    placeholder="email"
                    autoFocus
                    value={user.email}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                    className="bg-[#FAFAFA] w-full min-h-[44px] h-min rounded-[6px] pl-3 border-[2px] mt-5"
                  />
                  <div className="mt-5 ">Enter your password</div>
                  <input
                    required
                    type="password"
                    placeholder="password"
                    value={user.password}
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                    className="bg-[#FAFAFA] w-full min-h-[44px] h-min rounded-[6px] pl-3 border-[2px] mt-5"
                  />
                </div>
                {errorMessage && (
                  <div className="mt-3 text-red-500">{errorMessage}</div>
                )}{' '}
                <button
                  className="w-full h-[44px] rounded-[6px] bg-[#9B111E] text-white mt-8 hover:mt-7 hover:mb-1 duration-150"
                  type="submit"
                >
                  Continue
                </button>
              </form>
              <Link to="/login">
                <p className="text-[#808080] cursor-pointer text-center mt-5 mb-24">
                  Already have an account?
                </p>
              </Link>
            </>
          ) : (
            profileSetup.map(
              (item, index) =>
                index + 1 === currentNumber && (
                  <form
                    key={index}
                    className="rounded-[17px] w-[80vw] max-w-[576px] h-min mt-[56px] bg-white mx-auto equal-shadow p-[55px] text-[#374756] relative mb-24"
                    onSubmit={continueForm}
                  >
                    <div className="mx-auto w-min">
                      <item.svg className="w-[50px] h-[50px] text-[#374756]" />
                    </div>
                    <div className="text-center">
                      <h2 className="text-[26px] font-semibold mt-5">
                        {item.question}
                      </h2>
                    </div>

                    {item.input && (
                      <input
                        required
                        placeholder={item.input}
                        autoFocus
                        onChange={(e) => handleInputChange(e, item)}
                        className="bg-[#FAFAFA] w-full min-h-[44px] h-min rounded-[6px] pl-3 border-[2px] mt-5"
                      />
                    )}

                    {item.options && (
                      <div>
                        {item.options.map((option) => (
                          <div
                            key={option}
                            onClick={() => handleOptionClick(option, item)}
                            className="w-full h-min min-h-[44px] bg-[#FAFAFA] rounded-full grid place-content-center my-3 border-[2px] hover:bg-[#9B111E] hover:text-white hover:border-[#9B111E] duration-150 cursor-pointer"
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    )}

                    {item.range && (
                      <div className="mt-5">
                        <input
                          type="range"
                          min={item.range[0]}
                          max={item.range[1]}
                          value={selectedAge}
                          onChange={(e) => {
                            const newAge = e.target.value;
                            setSelectedAge(newAge);
                            setAge(Number(newAge));
                          }}
                          className="w-full"
                        />

                        <div className="mt-2 text-center">
                          Age: {selectedAge}
                        </div>
                      </div>
                    )}

                    {item.question === 'Please add 1-5 photos of yourself' && (
                      <div className="mt-5">
                        <input
                          required
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={handleImageUpload}
                        />
                        <div className="flex flex-wrap ">
                          {profilePictures?.map((image, idx) => (
                            <img
                              key={idx}
                              src={image}
                              alt="Uploaded Preview"
                              width="100"
                              className="object-cover object-center m-2 cursor-pointer h-[80px] w-[80px]"
                            />
                          ))}
                        </div>

                        {imagesLoading && <Loader size={20} />}

                        {imagesLoading && <span>Uploading images...</span>}

                        {profilePictures.length > 0 && (
                          <div>
                            {profilePictures.length} Images selected{' '}
                            <span
                              className="p-[1px] px-2 bg-gray-200 rounded-sm cursor-pointer w-max border-[1px] border-gray-600 text-black"
                              onClick={() => setProfilePictures([])}
                            >
                              reset
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    {!item.options && (
                      <button
                        className={cn(
                          'w-full h-[44px] rounded-[6px] bg-[#9B111E] text-white mt-8 hover:mt-7 hover:mb-1 duration-150',
                          continueDisabled && 'opacity-50 pointer-events-none',
                        )}
                        type="submit"
                        disabled={continueDisabled}
                      >
                        Continue
                      </button>
                    )}

                    {currentNumber > 1 && currentNumber <= 11 && (
                      <button
                        className="w-max flex flex-wrap absolute opacity-50 left-3 bottom-3 text-[14px]"
                        onClick={() => setCurrentNumber(currentNumber - 1)}
                      >
                        <BsArrowLeft className="relative mr-2 -bottom-1" /> go
                        back
                      </button>
                    )}
                  </form>
                ),
            )
          )}

          {currentNumber === 12 && (
            <div className="rounded-[17px] w-[80vw] max-w-[576px] h-min mt-[56px] bg-white mx-auto equal-shadow p-[55px] text-[#374756]">
              <div className="mx-auto w-min">
                <FiSun className="w-[50px] h-[50px] text-[#374756]" />
              </div>
              <div className="text-center">
                <h2 className="text-[26px] font-semibold mt-5">
                  Congratulations!
                </h2>
                <div className="mt-5">
                  {' '}
                  Your account has been created successfully!
                </div>
              </div>
              <Link to="/dashboard">
                <button
                  className="w-full h-[44px] rounded-[6px] bg-[#9B111E] text-white mt-8 hover:mt-7 hover:mb-1 duration-150"
                  onClick={() => setCurrentNumber(currentNumber + 1)}
                >
                  Continue
                </button>
              </Link>
            </div>
          )}
          {currentNumber === 12 && (
            <div>
              <p className="mt-5 text-[14px] text-center">
                I have read and agree to the{' '}
                <span
                  className="underline cursor-pointer text-sky-400 "
                  onClick={() => setTerms(!terms)}
                >
                  Terms of use{' '}
                </span>
              </p>

              {terms && (
                <div className="w-[80%] md:w-[50%] mx-auto mt-10 h-52 overflow-auto hide-scrollbar">
                  <h2 className="text-center text-[18px] font-bold">
                    Terms of Use
                  </h2>
                  <p className="mt-5">
                    https://www.pinasoulmate.com/ is a social networking service
                    that allows members to create unique personal profiles
                    online in order to find and communicate with old and new
                    friends. The service is operated by
                    https://www.pinasoulmate.com/. By using the
                    https://www.pinasoulmate.com/ Website you agree to be bound
                    by these Terms of Use (this "Agreement"), whether or not you
                    register as a member ("Member"). If you wish to become a
                    Member, communicate with other Members and make use of the
                    https://www.pinasoulmate.com/ services (the "Service"),
                    please read this Agreement and indicate your acceptance by
                    following the instructions in the Registration process.
                  </p>
                  <p className="mt-5">
                    This Agreement sets out the legally binding terms for your
                    use of the Website and your Membership in the Service.
                    https://www.pinasoulmate.com/ may modify this Agreement from
                    time to time and such modification shall be effective upon
                    posting by https://www.pinasoulmate.com/ on the Website. You
                    agree to be bound to any changes to this Agreement when you
                    use the Service after any such modification is posted. This
                    Agreement includes https://www.pinasoulmate.com/ policy for
                    acceptable use and content posted on the Website, your
                    rights, obligations and restrictions regarding your use of
                    the Website and the Service and
                    https://www.pinasoulmate.com/ Privacy Policy.
                  </p>
                  <p className="mt-5">
                    Please choose carefully the information you post on
                    https://www.pinasoulmate.com/ and that you provide to other
                    Members. Any photographs posted by you may not contain
                    nudity, violence, or offensive subject matter. Information
                    provided by other https://www.pinasoulmate.com/ Members (for
                    instance, in their Profile) may contain inaccurate,
                    inappropriate or offensive material, products or services
                    and https://www.pinasoulmate.com/ assumes no responsibility
                    nor liability for this material.
                  </p>
                  <p className="mt-5">
                    https://www.pinasoulmate.com/ reserves the right, in its
                    sole discretion, to reject, refuse to post or remove any
                    posting (including email) by you, or to restrict, suspend,
                    or terminate your access to all or any part of the Website
                    and/or Services at any time, for any or no reason, with or
                    without prior notice, and without liability.
                  </p>
                  <p className="mt-5 font-semibold">
                    https://www.pinasoulmate.com/ does not vet/screen or
                    recommend any members and is not an introduction agency. We
                    do not verify members backgrounds or make any warrant that
                    profile information is accurate. By using this service you
                    agree to take responsibility for your own actions if you
                    choose to meet anyone from this site.
                  </p>
                  <p className="mt-5">
                    By participating in any offline
                    https://www.pinasoulmate.com/ event, you agree to release
                    and hold https://www.pinasoulmate.com/ harmless from any and
                    all losses, damages, rights, claims, and actions of any kind
                    including, without limitation, personal injuries, death, and
                    property damage, either directly or indirectly related to or
                    arising from your participation in any such offline
                    https://www.pinasoulmate.com/ event.
                  </p>
                  <h2 className="text-center text-[16px] font-bold mt-5">
                    Terms of Use
                  </h2>
                  <p className="mt-5">
                    1) Your Interactions.<br></br> You are solely responsible
                    for your interactions and communication with other Members.
                    You understand that https://www.pinasoulmate.com/ does not
                    in any way screen its Members, nor does
                    https://www.pinasoulmate.com/ inquire into the backgrounds
                    of its Members or attempt to verify the statements of its
                    Members. https://www.pinasoulmate.com/ makes no
                    representations or warranties as to the conduct of Members
                    or their compatibility with any current or future Members.
                    We do however recommend that if you choose to meet or
                    exchange personal information with any member of
                    https://www.pinasoulmate.com/ then you should take it upon
                    yourself to do a background check on said person. In no
                    event shall https://www.pinasoulmate.com/ be liable for any
                    damages whatsoever, whether direct, indirect, general,
                    special, compensatory, consequential, and/or incidental,
                    arising out of or relating to the conduct of you or anyone
                    else in connection with the use of the Service, including
                    without limitation, bodily injury, emotional distress,
                    and/or any other damages resulting from communications or
                    meetings with other registered users of this Service or
                    persons you meet through this Service.
                  </p>
                  <p className="mt-5">
                    2) Eligibility.<br></br> Membership in the Service where
                    void is prohibited. By using the Website and the Service,
                    you represent and warrant that all registration information
                    you submit is truthful and accurate and that you agree to
                    maintain the accuracy of such information. You further
                    represent and warrant that you are 18 years of age or older
                    and that your use of the https://www.pinasoulmate.com/ shall
                    not violate any applicable law or regulation. Your profile
                    may be deleted without warning, if it is found that you are
                    misrepresenting your age. Your Membership is solely for your
                    personal use, and you shall not authorize others to use your
                    account, including your profile or email address. You are
                    solely responsible for all content published or displayed
                    through your account, including any email messages, and for
                    your interactions with other members.
                  </p>
                  <p className="mt-5">
                    3) Term/Fees. <br></br>This Agreement shall remain in full
                    force and effect while you use the Website, the Service,
                    and/or are a Member. You may terminate your membership at
                    any time. https://www.pinasoulmate.com/ may terminate your
                    membership for any reason without notice. By using the
                    Service and by becoming a Member, you acknowledge that
                    https://www.pinasoulmate.com/ reserves the right to charge
                    for the Service and has the right to terminate a Members
                    Membership if Member should breach this Agreement or fail to
                    pay for the Service, as required by this Agreement.
                  </p>
                  <p className="mt-5">
                    4) Non Commercial Use by Members.<br></br>
                    The Website is for the personal use of Members only and may
                    not be used in connection with any commercial endeavors
                    except those that are specifically endorsed or approved by
                    the management of https://www.pinasoulmate.com/. Illegal
                    and/or unauthorized use of the Website, including collecting
                    usernames and/or email addresses of Members by electronic or
                    other means for the purpose of sending unsolicited email or
                    unauthorized framing of or linking to the Website will be
                    investigated. Commercial advertisements, affiliate links,
                    and other forms of solicitation may be removed from member
                    profiles without notice and may result in termination of
                    membership privileges. Appropriate legal action will be
                    taken by https://www.pinasoulmate.com/ for any illegal or
                    unauthorized use of the Website.
                  </p>
                  <p className="mt-5">
                    5) Proprietary Rights in Content on
                    https://www.pinasoulmate.com/. <br></br>
                    https://www.pinasoulmate.com/ owns and retains all
                    proprietary rights in the Website and the Service. The
                    Website contains copyrighted material, trademarks, and other
                    proprietary information of https://www.pinasoulmate.com/ and
                    its licensors. Except for that information which is in the
                    public domain or for which you have been given written
                    permission, you may not copy, modify, publish, transmit,
                    distribute, perform, display, or sell any such proprietary
                    information.
                  </p>
                  <p className="mt-5">
                    6) User content<br></br>
                    a. You understand and agree that
                    https://www.pinasoulmate.com/ may review and delete all
                    content, messages, https://www.pinasoulmate.com/ Messenger
                    messages, photos or profiles (collectively, "Content") that
                    in the sole judgment of https://www.pinasoulmate.com/
                    violate this Agreement or which may be offensive, illegal or
                    violate the rights, harm, or threaten the safety of any
                    Member.
                    <br></br>
                    b. You are solely responsible for the Content that you
                    publish or display (hereinafter, "post") on the Service or
                    any material or information that you transmit to other
                    Members
                    <br></br>
                    c. By posting any Content to the public areas of the
                    Website, you hereby grant to https://www.pinasoulmate.com/
                    the non-exclusive, fully paid, worldwide license to use,
                    publicly perform and display such Content. This license will
                    terminate at the time you remove such Content from the
                    Website or notify us by email.
                    <br></br>
                    d. The following is a partial list of the kind of Content
                    that is illegal or prohibited on the Website.
                    https://www.pinasoulmate.com/ reserves the right to
                    investigate and take appropriate legal action in its sole
                    discretion against anyone who violates this provision,
                    including without limitation, removing the offending
                    communication from the Service and terminating the
                    membership of such violators. Prohibited Content includes
                    Content that:
                    <br></br>
                    i. is patently offensive and promotes racism, bigotry,
                    hatred or physical harm of any kind against any group or
                    individual;
                    <br></br>
                    ii. harasses or advocates harassment of another person;
                    <br></br>
                    iii. involves the transmission of "junk mail", "chain
                    letters," or unsolicited mass mailing or "spamming";
                    <br></br>
                    iv. promotes information that you know is false or
                    misleading or promotes illegal activities or conduct that is
                    abusive, threatening, obscene, defamatory or libelous;
                    <br></br>
                    v. promotes an illegal or unauthorized copy of another
                    persons copyrighted work, such as providing pirated computer
                    programs or links to them, providing information to
                    circumvent manufacture-installed copy-protect devices, or
                    providing pirated music or links to pirated music files;
                    <br></br>
                    vi. contains restricted or password only access pages or
                    hidden pages or images (those not linked to or from another
                    accessible page);
                    <br></br>
                    vii. provides material that exploits people under the age of
                    18 in a sexual or violent manner, or solicits personal
                    information from anyone under 18;
                    <br></br>
                    viii. provides instructional information about illegal
                    activities such as making or buying illegal weapons,
                    violating someones privacy, or providing or creating
                    computer viruses;
                    <br></br>
                    ix. solicits passwords or personal identifying information
                    for commercial or unlawful purposes from other users;
                    <br></br>
                    or x. involves commercial activities and/or sales without
                    our prior written consent such as contests, sweepstakes,
                    barter, advertising, or pyramid schemes.
                    <br></br>
                    e. You must use the Service in a manner consistent with any
                    and all applicable laws and regulations. f. You may not
                    engage in advertising to, or solicitation of, any Member to
                    buy or sell any products or services through the Service.
                    You may not transmit any chain letters or junk email to
                    other Members. Although https://www.pinasoulmate.com/ cannot
                    monitor the conduct of its Members off the Website, it is
                    also a violation of these rules to use any information
                    obtained from the Service in order to harass, abuse, or harm
                    another person, or in order to contact, advertise to,
                    solicit, or sell to any Member without their prior explicit
                    consent. In order to protect our Members from such
                    advertising or solicitation, https://www.pinasoulmate.com/
                    reserves the right to restrict the number of emails which a
                    Member may send to other Members in any 24-hour period to a
                    number which https://www.pinasoulmate.com/ deems appropriate
                    in its sole discretion.
                    <br></br>
                    g. You may not cover or obscure the banner advertisements on
                    your personal profile page, or any
                    https://www.pinasoulmate.com/ page via HTML/CSS or any other
                    means.
                    <br></br>
                    h. In the event of a payment dispute, data of your activity
                    at this site may be shared with the payment company to prove
                    usage.
                    <br></br>
                    i. Any automated use of the system, such as using scripts to
                    add friends, is prohibited.
                    <br></br>
                    j. You may not attempt to impersonate another user or person
                    who is not a member of https://www.pinasoulmate.com/.
                    <br></br>
                    l. You may not sell or otherwise transfer your profile.
                  </p>
                  <p className="mt-5">
                    7) Copyright Policy. <br></br>
                    You may not post, distribute, or reproduce in any way any
                    copyrighted material, trademarks, or other proprietary
                    information without obtaining the prior written consent of
                    the owner of such proprietary rights. It is the policy of
                    https://www.pinasoulmate.com/ to terminate membership
                    privileges of any member who repeatedly infringes copyright
                    upon prompt notification to https://www.pinasoulmate.com/ by
                    the copyright owner or the copyright owners legal agent.
                    Without limiting the foregoing, if you believe that your
                    work has been copied and posted on the Service in a way that
                    constitutes copyright infringement, please provide our
                    Copyright Agent with the following information: an
                    electronic or physical signature of the person authorized to
                    act on behalf of the owner of the copyright interest; a
                    description of the copyrighted work that you claim has been
                    infringed; a description of where the material that you
                    claim is infringing is located on the Website; your address,
                    telephone number, and email address; a written statement by
                    you that you have a good faith belief that the disputed use
                    is not authorized by the copyright owner, its agent, or the
                    law; a statement by you, made under penalty of perjury, that
                    the above information in your notice is accurate and that
                    you are the copyright owner or authorized to act on the
                    copyright owners behalf. https://www.pinasoulmate.com/
                    Copyright Agent for notice of claims of copyright
                    infringement can be reached via email address.
                  </p>
                  <p className="mt-5">
                    8) Member Disputes.<br></br>
                    You are solely responsible for your interactions with other
                    https://www.pinasoulmate.com/ Members.
                    https://www.pinasoulmate.com/ reserves the right, but has no
                    obligation, to monitor disputes between you and other
                    Members.
                  </p>
                  <p className="mt-5">
                    9) Disclaimers.<br></br>
                    https://www.pinasoulmate.com/ is not responsible for any
                    incorrect or inaccurate content posted on the Website or in
                    connection with the Service provided, whether caused by
                    users of the Website, Members or by any of the equipment or
                    programming associated with or utilized in the Service.
                    https://www.pinasoulmate.com/ is not responsible for the
                    conduct, whether online or offline, of any user of the
                    Website or Member of the Service.
                    https://www.pinasoulmate.com/ assumes no responsibility for
                    any error, omission, interruption, deletion, defect, delay
                    in operation or transmission, communications line failure,
                    theft or destruction or unauthorized access to, or
                    alteration of, any user or Member communication.
                    https://www.pinasoulmate.com/ is not responsible for any
                    problems or technical malfunction of any telephone network
                    or lines, computer online systems, servers or providers,
                    computer equipment, software, failure of any email or
                    players due to technical problems or traffic congestion on
                    the Internet or at any Website or combination thereof,
                    including any injury or damage to users and/or Members or to
                    any persons computer related to or resulting from
                    participation or downloading materials in connection with
                    the Website and/or in connection with the Service. Under no
                    circumstances shall https://www.pinasoulmate.com/ be
                    responsible for any loss or damage, including personal
                    injury or death, resulting from use of t he Website or the
                    Service or from any Content posted on the Website or
                    transmitted to Members, or any interactions between users of
                    the Website, whether online or offline. The Website and the
                    Service are provided "AS-IS" and
                    https://www.pinasoulmate.com/ expressly disclaims any
                    warranty of fitness for a particular purpose or
                    non-infringement. https://www.pinasoulmate.com/ cannot
                    guarantee and does not promise any specific results from use
                    of the Website and/or the Service.
                  </p>
                  <p className="mt-5">
                    10) Limitation on Liability.<br></br>
                    IN NO EVENT SHALL https://www.pinasoulmate.com/ BE LIABLE TO
                    YOU OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL,
                    EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES,
                    INCLUDING LOST PROFIT DAMAGES ARISING FROM YOUR USE OF THE
                    WEB SITE OR THE SERVICE, EVEN IF
                    https://www.pinasoulmate.com/ HAS BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
                    CONTRARY CONTAINED HEREIN, https://www.pinasoulmate.com/.S
                    LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF
                    THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO
                    AMOUNT PAID, IF ANY, BY YOU TO https://www.pinasoulmate.com/
                    FOR THE SERVICE DURING THE TERM OF MEMBERSHIP.
                  </p>
                  <p className="mt-5">
                    11) Disputes.<br></br>
                    Governing law: The Terms and any dispute or claim arising
                    out of or in connection with it or its subject matter shall
                    be are governed in accordance with Singaporean law. If there
                    is any dispute about or involving the Website and/or the
                    Service, by using the Website, you agree that any dispute
                    shall be governed by the laws of the area in which we are
                    based without regard to conflict of law provisions and you
                    agree to personal jurisdiction by and venue in the area in
                    which we are based.
                  </p>
                  <p className="mt-5">
                    12) Indemnity.<br></br>
                    You agree to indemnify and hold
                    https://www.pinasoulmate.com/, its subsidiaries, affiliates,
                    officers, agents, and other partners and employees, harmless
                    from any loss, liability, claim, or demand, including
                    reasonable attorneys fees, made by any third party due to or
                    arising out of your use of the Service in violation of this
                    Agreement and/or arising from a breach of this Agreement
                    and/or any breach of your representations and warranties set
                    forth above.
                  </p>
                  <p className="mt-5">
                    13) Other.<br></br>
                    This Agreement is accepted upon your use of the Website and
                    is further affirmed by you becoming a Member of the Service.
                    This Agreement constitutes the entire agreement between you
                    and https://www.pinasoulmate.com/ regarding the use of the
                    Website and/or the Service. The failure of
                    https://www.pinasoulmate.com/ to exercise or enforce any
                    right or provision of this Agreement shall not operate as a
                    waiver of such right or provision. The section titles in
                    this Agreement are for convenience only and have no legal or
                    contractual effect. Please contact us with any questions
                    regarding this Agreement. https://www.pinasoulmate.com/ is a
                    trademark of owners business name.
                  </p>
                  <p className="mt-5">
                    14) Data security https://www.pinasoulmate.com/ cannot be
                    held reliable for loss of your personal data, and is not
                    responsible for backing up any of your personal data
                    including text/images/video or other.
                    https://www.pinasoulmate.com/ makes no claim that your
                    account data is stored securely, and should data or personal
                    information be leaked either through a user problems or
                    server problems https://www.pinasoulmate.com/ cannot be held
                    responsible.
                  </p>
                  <p className="mt-5 font-semibold">
                    I HAVE READ THIS AGREEMENT AND AGREE TO ALL OF THE
                    PROVISIONS CONTAINED ABOVE.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Setup;
