import React from 'react';
import { Link } from 'react-router-dom';

function ErrorPage() {
  return (
    <>
      <div className="w-full min-h-screen h-min bg-[#EAE0C8] overflow-hidden  text-[#374756] flex items-center">
        <div className="mx-auto w-max">
          <p className="text-[24px] font-bold">Oops something went wrong</p>
          <div className="grid grid-rows-2">
            <Link
              to="/dashboard"
              className="w-max mt-10 bg-gray-100 border-gray-300 hover:bg-gray-200 hover:border-gray-400 duration-150 border-[2px] rounded-full p-2 px-5 grid mx-auto">
              go back to dashboard
            </Link>
            <Link
              to="/"
              className="w-max mt-10 bg-gray-100 border-gray-300 hover:bg-gray-200 hover:border-gray-400 duration-150 border-[2px] rounded-full p-2 px-5 mx-auto">
              go back to landing
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ErrorPage;
