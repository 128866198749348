import { create } from 'zustand';

const useUserStore = create((set) => ({
  user: null,
  userId: null,
  isSigningOut: false,
  authState: 'loading',
  setUser: (user) =>
    set({
      user,
      userId: user?.uid,
      authState: user ? 'logged-in' : 'logged-out',
    }),
  setSigningOut: (isSigningOut) => set({ isSigningOut }),
}));

export default useUserStore;
