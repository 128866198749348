import { MdDriveFileRenameOutline } from 'react-icons/md';
import { PiPlusMinusBold } from 'react-icons/pi';
import { BsGenderAmbiguous } from 'react-icons/bs';
import {
  BiSolidMapPin,
  BiSolidColor,
  BiSolidBookBookmark
} from 'react-icons/bi';
import { TbMoodKid } from 'react-icons/tb';
import { AiFillHeart, AiFillLike } from 'react-icons/ai';
import { HiInformationCircle, HiOutlinePhotograph } from 'react-icons/hi';

const profileQuestions = [
  {
    svg: MdDriveFileRenameOutline,
    question: 'What is your name?',
    input: 'input here'
  },
  {
    svg: PiPlusMinusBold,
    question: 'What is your age?',
    range: [18, 100]
  },
  {
    svg: BsGenderAmbiguous,
    question: 'What is your gender?',
    options: ['Male', 'Female']
  },
  {
    svg: BiSolidMapPin,
    question: 'What city and country do you live in?',
    input: 'input here'
  },
  {
    svg: TbMoodKid,
    question: 'Do you have children?',
    options: ['No', 'Yes']
  },
  {
    svg: BiSolidColor,
    question: 'What is your Ethnicity?',
    options: [
      'Black / African Descent',
      'Asian',
      'Hispanic / Latin',
      'Middle Eastern',
      'Mixed',
      'White / Caucasian'
    ]
  },
  {
    svg: AiFillHeart,
    question: 'What type of relationship are you looking for?',
    options: [
      'Friendship',
      'Short term dating',
      'Long term dating',
      'Casual dating'
    ]
  },
  {
    svg: BiSolidBookBookmark,
    question: 'What is your religion?',
    options: [
      'Buddhist',
      'Christian',
      'Christian - Catholic',
      'Hindu',
      'Jewish',
      'Muslim',
      'Not religious',
      'Sikh',
      'Other'
    ]
  },
  {
    svg: HiInformationCircle,
    question: 'Tell us about yourself!',
    input: 'input here'
  },
  {
    svg: AiFillLike,
    question: 'What are you looking for in a partner?',
    input: 'input here'
  },
  {
    svg: HiOutlinePhotograph,
    question: 'Please add 1-5 photos of yourself'
  }
];

export default profileQuestions;
