import { GridLoader } from 'react-spinners';

const Loader = ({ size, color, className }) => {
  return (
    <GridLoader
      color={color || '#9b111e'}
      size={size || 50}
      aria-label="Loading Spinner"
      data-testid="loader"
      className={className}
    />
  );
};

export default Loader;
