import { create } from 'zustand';

const useProfileObject = create((set) => ({
  id: '',
  name: '',
  age: 18,
  gender: '',
  location: '',
  children: '',
  ethnicity: '',
  relationship: '',
  religion: '',
  aboutMe: '',
  seeking: '',
  images: [],
  lastActive: '',

  setId: (text) => set((state) => ({ ...state, id: text })),
  setName: (text) => set((state) => ({ ...state, name: text })),
  setAge: (number) => set((state) => ({ ...state, age: number })),
  setGender: (text) => set((state) => ({ ...state, gender: text })),
  setLocation: (text) => set((state) => ({ ...state, location: text })),
  setChildren: (text) => set((state) => ({ ...state, children: text })),
  setEthnicity: (text) => set((state) => ({ ...state, ethnicity: text })),
  setRelationship: (text) => set((state) => ({ ...state, relationship: text })),
  setReligion: (text) => set((state) => ({ ...state, religion: text })),
  setAboutMe: (text) => set((state) => ({ ...state, aboutMe: text })),
  setSeeking: (text) => set((state) => ({ ...state, seeking: text })),
  setImages: (array) => set((state) => ({ ...state, images: array })),
  setLastActive: (text) => set((state) => ({ ...state, lastActive: text })),

  // Utility function to get all data
  getAllData: () => {
    return {
      id: '',
      name: '',
      age: 1,
      gender: '',
      location: '',
      children: '',
      ethnicity: '',
      relationship: '',
      religion: '',
      aboutMe: '',
      seeking: '',
      images: [],
      lastActive: ''
    };
  }
}));

export default useProfileObject;
